import {AxiosResponse} from 'axios';
import axios from '../utils/axios';
import environments from '../utils/environments';

export class CoreService {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = environments.apiUrl;
    }

    upload(data: FormData): Promise<AxiosResponse> {
        return axios.post(`${this.apiUrl}/api/v1/files/upload`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    getLinks(userId: string): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/files/getLinks?v=1.0`, {
            params: {
                userId
            }
        });
    }

    getUsers(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/users`);
    }

    getStatusList(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/files/getStatusList`);
    }

    getPreOrders(search?: string): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/preOrders`, {
            params: {
                search
            }
        });
    }

    getPreOrder(pre_order_id: string|number): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/preOrders/` + pre_order_id);
    }

    createPreOrders(name: string): Promise<AxiosResponse> {
        return axios.post(`${this.apiUrl}/api/v1/preOrders/create`, {name});
    }

    addPreOrderLink(pre_order_id: number, link_id: number): Promise<AxiosResponse> {
        return axios.post(`${this.apiUrl}/api/v1/preOrders/addLink`, {pre_order_id, link_id});
    }

}

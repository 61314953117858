import React from 'react';

import localStyles from './styles.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useGetPreOrderQuery} from "../../../storage/api";
import Button from "../../../components/Button";
import {ROUTES} from "../../../utils/constants";
import links from "../../Links";
import {APILinksItemData, LinkFileInterface} from "../../../interfaces/entity";

const PreOrdersItemPage = () => {

    const {id} = useParams()
    const navigate = useNavigate();

    const {data: preOrder} = useGetPreOrderQuery(id);

    console.log(preOrder, Object.keys((preOrder?.tracknumbers ?? {})))

    return (
        <div className={localStyles.wrapper}>
            <div className={'d-flex'}>
                <Button id={'back-to-pre-orders'} size={'small'} background={'bg-outline'} text={'Back to Pre Orders'}
                        click={() => {
                            navigate('/' + ROUTES.PRE_ORDERS);
                        }}
                />
            </div>
            <div className={'d-flex justify-content-start'}>
                <h2>#{preOrder?.name}</h2>
            </div>
            <div className={localStyles.container}>
                {Object.keys((preOrder?.tracknumbers ?? {})).map((key: string) => {
                    return (
                        <div className={localStyles.trackContainer} key={key}>
                            <div className={localStyles.tracknumber}><h3>{key}</h3></div>
                            <div className={localStyles.imageListContainer}>
                                {(preOrder?.tracknumbers as any ?? {})[key].map((link: APILinksItemData) => {
                                    return (
                                        link.files.map(file => {
                                            return (
                                                <img src={file.bb_link_to_file} alt=""/>
                                            )
                                        })
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            {/*<div className={localStyles.container}>*/}
            {/*    {(preOrder?.files ?? []).map((file) => {*/}
            {/*        return (*/}
            {/*            <div key={file.id} className={localStyles.imageContainer}>*/}
            {/*                <img onDoubleClick={(event) => {*/}
            {/*                    window.open(file.bb_link_to_file, '_blank')*/}
            {/*                }} src={file.bb_link_to_file} alt={file.file_name}*/}
            {/*                     onMouseDown={event => {*/}
            {/*                         (event.target as HTMLImageElement).style.scale = '2'*/}
            {/*                     }}*/}
            {/*                     onMouseUp={event => {*/}
            {/*                         (event.target as HTMLImageElement).style.scale = ''*/}
            {/*                     }}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    );
};

export default <PreOrdersItemPage/>;

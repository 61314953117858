import React, {useEffect, useState} from "react";
import localStyles from "./styles.module.scss";
import Button from "../Button";
import {useMediaQuery} from "react-responsive";
import FileUploadForm from "../../pages/Links/FileUploadForm";
import Modal from "../Modal";
import {AuthService} from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/constants";
import i18n from "i18next";
import translate from "../../utils/translate";
import {useAppDispatch, useAppSelector} from "../../storage/hooks";
import {setLang} from "../../storage/global";
import {setLocalStorageLang} from "../../utils/methods";
import {useGetLinksQuery} from "../../storage/api";

const Header = () => {
    const authService = new AuthService();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const isMobile: boolean = useMediaQuery({query: `(max-width: 640px`});
    const [username, setUsername] = useState<string>("");

    const lang = useAppSelector(state => state.global.lang)
    const {data: links, refetch: refetchLinks, } = useGetLinksQuery();

    useEffect(() => {
        refetchLinks()
    }, [lang]);

    useEffect(() => {
        const username: string = localStorage.getItem("username");
        setUsername(username)
    }, []);

    function handleLang(lang: string) {
        i18n.changeLanguage(lang);
        dispatch(setLang(lang))
        setLocalStorageLang(lang)
    }



    return (<div className={localStyles.header}>
        <div className={`container ${localStyles.container}`}>

            <Button
                id={"logout"}
                size={"middle"}
                background={"bg-outline"}
                text={isMobile ? "" : translate('logout')}
                click={() => {
                    authService.logout().then(() => {
                        navigate("/" + ROUTES.SIGN_IN);
                    });
                }}
                styles={isMobile
                    ? {
                        width: "unset",
                        minWidth: "unset",
                    }
                    : null}
                type={"button"}
                classes={`text-capitalize ${localStyles.logoutButton}`}
                appendBefore={
                    <svg xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         height="24"
                         viewBox="0 0 24 24"
                         width="24"
                    >
                        <path
                            d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
                            stroke="#000"
                        />
                    </svg>
                }
            />
            <p className={localStyles.username}>{username}</p>
            <div className={'d-flex'} style={{gap: '1rem'}}>
                <Button id={'en'}
                        size={'small'}
                        disabled={lang === 'en'}
                        background={lang === 'en' ? 'bg-green' : 'bg-outline'}
                        text={'EN'}
                        click={() => handleLang('en')}
                />
                <Button id={'cn'}
                        size={'small'}
                        disabled={lang === 'cn'}
                        background={lang === 'cn' ? 'bg-green' : 'bg-outline'}
                        text={'CN'}
                        click={() => handleLang('cn')}
                />
            </div>
        </div>
    </div>)
}

export default Header;

import ErrorBoundary from '../components/ErrorBoundary';
import {ROUTES} from '../utils/constants';
import Links from "../pages/Links";
import Login from "../pages/Login";
import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import NavigateToLinks from "../components/NavigateToLinks";
import Dashboard from "../pages/Dashboard";
import PreOrders from "../pages/PreOrders";
import PreOrdersItemPage from "../pages/PreOrders/PreOrdersItemPage";

const routes = [
    {
        path: '/',
        element: App,
        errorElement: ErrorBoundary,
        children: [
            {
                index: true,
                element: NavigateToLinks,
                errorElement: ErrorBoundary
            },
            {
                path: ROUTES.DASHBOARD,
                element: Dashboard,
                errorElement: ErrorBoundary
            },
            {
                path: ROUTES.LINKS,
                element: Links,
                errorElement: ErrorBoundary,
            },
            {
                path: ROUTES.PRE_ORDERS,
                element: PreOrders,
                errorElement: ErrorBoundary,
            },
            {
                path: ROUTES.PRE_ORDERS + '/:id',
                element: PreOrdersItemPage,
                errorElement: ErrorBoundary,
            },
        ]
    },
    {
        path: ROUTES.SIGN_IN,
        element: Login,
        errorElement: ErrorBoundary,
    },
    {
        path: '*',
        element: NavigateToLinks
    }
];

export default createBrowserRouter(routes);

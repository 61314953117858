import React, {BaseSyntheticEvent, useEffect, useMemo, useState} from "react";
import localStyles from "./styles.module.scss";
import Card from "../../../components/Card";
import icons from "../../../utils/icons";
import {copy} from "../../../utils/methods";
import Button from "../../../components/Button";
import {useGetLinksQuery, useGetPreOrdersQuery, useLazyGetPreOrdersQuery} from "../../../storage/api";
import translate from "../../../utils/translate";
import Modal from "../../../components/Modal";
import CreatePreOrder from "../../../components/CreatePreOrder";
import {Select} from "grommet";
import {CoreService} from "../../../services/core.service";
import {toast} from "react-toastify";
import PreOrderBlock from "./PreOrderBlock";

interface Props {
    id: number;
    date: string;
    url: string;
    user: {
        username: string
    };
    files: {
        id: number;
        origin_name: string;
        bb_link_to_file: string;
        temporary_link_to_file: string;
    }[];
    pre_orders: {
        id: number;
        user_id: number;
        name: string;
        hash: string;
        created_at: string;
        laravel_through_key: number;
    },
    tracknumber: string
}

const FilesListItem = ({date, files, id, url:link, user, pre_orders, tracknumber}: Props) => {

    // console.log(preOrders)

    const formattedDate: string = useMemo(() => {
        const dateOfCreation = new Date(date);
        if (isNaN(dateOfCreation?.getTime())) {
            return;
        }
        const year = dateOfCreation.getFullYear();
        const month = String(dateOfCreation.getMonth() + 1).padStart(2, '0');
        const day = String(dateOfCreation.getDate()).padStart(2, '0');
        const hours = String(dateOfCreation.getHours()).padStart(2, '0');
        const minutes = String(dateOfCreation.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }, [date]);

    // console.log(pre_orders, links)

    return (<Card className={localStyles.container} onClick={() => {
        window.open(link, '_blank')
    }}>
        <div><strong>#{id}</strong> | {user?.username}</div>
        <div className={localStyles.linkContainer}>

            <div className={'d-flex align-items-center'} style={{
                gap: 15
            }}>
                <Button
                    click={(event: BaseSyntheticEvent) => copy(link, null, event)}
                    id={'copy-link'}
                    size={'middle'}
                    background={'bg-primary'}
                    type={'button'}
                    text={translate('copy_link')}
                    classes={localStyles.copyButton}
                    appendBefore={<img className={localStyles.icon}
                                       src={icons.copy_all}
                                       alt="copyImg"
                    />}
                />
                {/*<a className={localStyles.link} target='_blank' href={link}>Link</a>*/}
            </div>
            <div onClick={event => event.stopPropagation()} style={{
                cursor: 'text'
            }}>
                <p>pre-order: <strong>{pre_orders?.name}</strong></p>
                <p>tracknumber: <strong>{tracknumber}</strong></p>
            </div>
            <div className={'d-flex justify-content-end'} style={{gap: 10}}>
                {(files ?? []).slice(0, 3).map(file =>
                    <img loading='lazy' key={file?.id} width={50} height={50}
                         src={file?.bb_link_to_file ?? file?.temporary_link_to_file} alt={file?.origin_name}/>
                )}
            </div>
        </div>
        <p className={localStyles.date} onClick={event => event.stopPropagation()}>{formattedDate}</p>
    </Card>)
}

export default FilesListItem;

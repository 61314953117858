import {AxiosResponse} from 'axios';
import axios from '../utils/axios';
import environments from '../utils/environments';

export class DashboardService {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = environments.apiUrl;
    }

    getAllCountFiles(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/dashboard/getAllCountFiles?v=1.0`)
    }
    getAllCountFilesGroupedByUser(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/dashboard/getAllCountFilesGroupedByUser?v=1.0`)
    }
    getAllCountFilesForAllTimes(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/dashboard/getAllCountFilesForAllTimes?v=1.0`)
    }

}

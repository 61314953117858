import React, {useEffect, useState} from "react";
import localStyles from "./styles.module.scss";
import {CoreService} from "../../../services/core.service";
import useToastifyHook from "../../../hooks/useToastifyHook";
import useViewGlobalBackground from "../../../hooks/useViewGlobalBackground";
import uuid from "react-uuid";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import InputErrorBlock from "../../../components/Inputs/InputErrorBlock";
import PhotoUploader from "../../../components/PhotoUploader";
import ProgressSpinner from "../../../components/ProgressSpinner";
import {useGetLinksQuery} from "../../../storage/api";
import translate from "../../../utils/translate";
import {parseNumber} from "../../../utils/methods";
import {COMMERCIAL_IDS} from "../../../utils/constants";

interface ParcelInterface {
    customer_number: string;
    tracknumber: string;
    doc_number: string;
    doc_date: string;
    package_id: string;
    urls: {
        url: string,
        file_name: string,
    }[]
}

interface ParcelResponseInterface {
    response: ParcelInterface[]
}

interface Props {
    close?: () => void;
}

const FileUploadForm = ({close}: Props) => {
    const coreService = new CoreService();

    // const {data, refetch: refetchLinks} = useGetLinksQuery(null)
    const userid = localStorage.getItem('userid').toString()

    const toast = useToastifyHook();
    const showBackGround = useViewGlobalBackground()

    const [keyImageComponent, setKeyImageComponent] = useState<string>(uuid())
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className={localStyles.container}>
            <Formik
                initialValues={{
                    images: [],
                }}
                onSubmit={(values, formikHelpers) => {

                    showBackGround(true)
                    setLoading(true)

                    const formData = new FormData();
                    for (let i = 0; i < values.images.length; i++) {
                        const blob = values.images[i]?.blob;
                        const fileOrigin = values.images[i]?.file;
                        const name = values.images[i].name;
                        const file = !fileOrigin ? new File([blob], name) : fileOrigin;
                        formData.append(`files[${i}]`, file);
                    }

                    // console.log(formData)
                    // return;

                    coreService.upload(formData)
                        .then(() => {
                            toast(translate('success_upload_file_to_queue'), {type: 'success'})
                            formikHelpers.resetForm();
                            setKeyImageComponent(uuid())
                        })
                        .then(() => {
                            setLoading(false);
                            close();
                            showBackGround(false);
                        })
                        .catch(({response}) => {
                            toast(response?.statusText + "|ERROR_SEND_PHOTO", {type: 'error'})
                        })
                        .finally(() => {
                            formikHelpers.setSubmitting(false);
                            // refetchLinks()
                        })
                }}
                validationSchema={Yup.object().shape({
                    images: Yup
                        .array()
                        .min(1, translate('files_not_found'))
                        .required(translate('files_not_found')),
                })}
            >
                {({
                      handleSubmit,
                      setFieldValue,
                      errors,
                      touched,
                      values,
                      resetForm,
                      isSubmitting
                  }) => {
                    function clearForm() {
                        resetForm();
                        setKeyImageComponent(uuid())
                        toast(translate('form_reset'), {type: 'success'})
                        close && close();
                    }

                    return (
                        <div className={`${localStyles.card} custom-scroll`}>
                            <Form onSubmit={handleSubmit} noValidate className={localStyles.form}>
                                <div className={localStyles.inputBlock}>
                                    <div>
                                        <PhotoUploader
                                            key={keyImageComponent}
                                            handleImages={(images) => {
                                                console.log(images.length)
                                                setFieldValue('images', images)
                                            }}
                                            className={`${touched.images && errors.images ? 'is-invalid' : ''}`}
                                        />
                                        <InputErrorBlock
                                            errorMessage={touched.images ? errors.images?.toString() : ''}/>
                                    </div>
                                </div>
                                <div>
                                    <Button id={'send'}
                                            size={'large'}
                                            background={'bg-primary'}
                                            text={loading ? '' : translate('send')}
                                            uppercase
                                            containerClass={'position-relative'}
                                            type={'submit'}
                                            disabled={isSubmitting}
                                            appendBefore={(
                                                <div data-if={isSubmitting} className={localStyles.loader}>
                                                    <ProgressSpinner strokewidth={3} animationduration={3}/>
                                                </div>
                                            )}
                                    />
                                </div>
                                <div>
                                <Button id={'reset'}
                                            size={'large'}
                                            background={'bg-danger'}
                                            text={translate('cancel')}
                                            uppercase
                                            click={() => {
                                                clearForm()
                                            }}
                                    />
                                </div>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
}

export default FileUploadForm;

import React, {useEffect} from "react";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import GlobalBackground from "./components/GlobalBackground";
import './styles/app.scss';
import * as Sentry from "@sentry/react";
import i18n from "i18next";
import {useAppSelector} from "./storage/hooks";
import localStyles from "./app.styles.module.scss";
import Header from "./components/Header";
import {ROUTES} from "./utils/constants";

const navigateList = [
    {
        path: ROUTES.DASHBOARD,
        label: "Dashboard",
    },
    {
        path: ROUTES.LINKS,
        label: "Links",
    },
    {
        path: ROUTES.PRE_ORDERS,
        label: 'PreOrders'
    },
]

const App = () => {

    const lang = useAppSelector(state => state.global.lang)
    const locationPath = useLocation().pathname.replace(/^\/|\/$/g, '');

    return (
        <div className={localStyles.container}>
            <Header/>
            <main className={localStyles.main}>
                <aside className={localStyles.navMenu}>
                    {navigateList.map((item, i) => {
                        const regexPath = new RegExp('^(' + item.path + ')', 'g')
                        return <NavLink aria-selected={regexPath.test(locationPath)} key={i} to={item.path}>{item.label}</NavLink>
                    })}
                </aside>
                <Outlet key={lang}/>
            </main>
            <ToastContainer/>
            <GlobalBackground/>
        </div>
)
    ;
}

const NewApp = Sentry.withProfiler(App);

export default <NewApp/>;

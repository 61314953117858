import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

const NavigateToLinks = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/' + ROUTES.LINKS);
    }, []);

    return <div></div>;
};

export default <NavigateToLinks/>;

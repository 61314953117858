import React, {useEffect} from 'react';

import Chart, {ChartConfiguration} from 'chart.js/auto';
import {DashboardService} from "../../services/dashboard.service";
import {ChartData} from "chart.js/dist/types";
import localStyles from './styles.module.scss'


const getRandomColor = (seed: number) => {
    let color = '#';
    const possibleChars = '0123456789ABCDEF';
    const pseudoRandom = () => {
        seed = Math.sin(seed) * 10000;
        return seed - Math.floor(seed);
    };
    for (let i = 0; i < 6; i++) {
        color += possibleChars[Math.floor(pseudoRandom() * 16)];
    }
    return color;
};
const Dashboard = () => {

    useEffect(() => {

        const dashboardService = new DashboardService();

        dashboardService.getAllCountFilesForAllTimes().then(({data}: {data: any[]}) => {
            return data.filter((value, index, array) => index % 7 === 0 || index === array.length - 1)
        }).then((data: { date: string, daily_count: number, cumulative_count: number }[]) => {
            const dataChart: ChartData = {
                labels: data.map(value => value.date),
                datasets: [
                    {
                        label: 'Count of photos per day',
                        data: data.map(value => value.cumulative_count),
                        borderColor: 'red',
                        backgroundColor: 'white',
                        fill: false,
                    },
                ]
            };

            const config: ChartConfiguration = {
                type: 'line',
                data: dataChart,
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Photo counter'
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                        },
                        y: {
                            display: true,
                            type: 'logarithmic',
                        }
                    }
                },
            };

            const ctx = document.getElementById('all_count_chart_for_all_times') as HTMLCanvasElement;
            const myChart = new Chart(ctx, config);
        })

        dashboardService.getAllCountFilesGroupedByUser().then(({data}: { data: any }) => {
            const dataChart: ChartData = {
                labels: data.map((value: any) => value.username),
                datasets: [
                    {
                        label: 'Count of photos',
                        data: data.map((value: any) => value.count),
                        backgroundColor: data.map((value: any) => getRandomColor(value.user_id)),
                    }
                ]
            };
            const config: ChartConfiguration = {
                type: 'pie',
                data: dataChart,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Count of photos for all times'
                        }
                    }
                },
            };

            const ctx = document.getElementById('all_count_chart_by_user') as HTMLCanvasElement;
            const myChart = new Chart(ctx, config);
        })

        dashboardService.getAllCountFiles().then(({data}: { data: [{ date: string, count: number }] }) => {
            const dataChart: ChartData = {
                labels: data.map(value => value.date),
                datasets: [
                    {
                        label: 'Count of photos per day',
                        data: data.map(value => value.count),
                        borderColor: 'red',
                        backgroundColor: 'white',
                        fill: false,
                    },
                ]
            };

            const config: ChartConfiguration = {
                type: 'line',
                data: dataChart,
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Photo counter'
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                        },
                        y: {
                            display: true,
                            type: 'logarithmic',
                        }
                    }
                },
            };

            const ctx = document.getElementById('all_count_chart') as HTMLCanvasElement;
            const myChart = new Chart(ctx, config);
        })
    }, []);

    return (
        <div className={localStyles.container}>
            {/*<div className={localStyles.Section_1}>*/}
            {/*    <canvas id="all_count_chart"></canvas>*/}
            {/*</div>*/}
            <div className={localStyles.Section_2}>
                <div>
                    <canvas id="all_count_chart"></canvas>
                </div>
                <div>
                <canvas id="all_count_chart_for_all_times"></canvas>
                </div>
                <div>
                <canvas id="all_count_chart_by_user"></canvas>
                </div>
            </div>
        </div>
    );
};

export default <Dashboard/>;

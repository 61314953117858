import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import axios from "../utils/axios";
import environments from "../utils/environments";
import {CoreService} from "../services/core.service";
import {APILinksItemData, PreOrderInterface, PreOrderItemInterface} from "../interfaces/entity";


export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({baseUrl: "http://localhost:7070/api/"}),
    endpoints: (builder) => ({
        getLinks: builder.query<APILinksItemData[], string|void>({
            queryFn: async (userId: string) => {
                try {
                    const response = await (new CoreService()).getLinks(userId)
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        }),
        getUsers: builder.query<{id: number, username: string}[], void>({
            queryFn: async () => {
                try {
                    const response = await (new CoreService()).getUsers()
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        }),
        getPreOrders: builder.query<PreOrderInterface[], string>({
            queryFn: async (search) => {
                try {
                    const response = await (new CoreService()).getPreOrders(search)
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        }),
        getPreOrder: builder.query<PreOrderItemInterface, string|number>({
            queryFn: async (pre_order_id) => {
                try {
                    const response = await (new CoreService()).getPreOrder(pre_order_id)
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        }),
        createPreOrders: builder.mutation<PreOrderInterface, string>({
            queryFn: async (name: string) => {
                try {
                    const response = await (new CoreService()).createPreOrders(name)
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
        }),
    }),
});

export const {
    useGetLinksQuery,
    useGetUsersQuery,
    useGetPreOrdersQuery,
    useGetPreOrderQuery,
    //Lazy
    useLazyGetPreOrdersQuery,
    //Mutation
    useCreatePreOrdersMutation,
} = api;

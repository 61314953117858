import React, {useCallback, useEffect, useState} from "react";
import localStyles from "./styles.module.scss";
import FilesListItem from "./FilesListItem";
import {useGetLinksQuery, useGetUsersQuery} from "../../storage/api";
import {socket} from "../../utils/socket";
import SelectFieldComponent, {
    SelectOptionElementsInterface,
    SelectOptionInterface
} from "../../components/SelectFieldComponent";
import Button from "../../components/Button";
import translate from "../../utils/translate";
import {useMediaQuery} from "react-responsive";
import FileUploadForm from "./FileUploadForm";
import Modal from "../../components/Modal";

type filterType = {
    userSelected: SelectOptionInterface | SelectOptionElementsInterface | any
}

const Links = () => {

    const userAdminIds = [1, 15]

    const userid = localStorage.getItem('userid') ?? 0

    const isMobile: boolean = useMediaQuery({query: `(max-width: 640px`});

    const [list, setList] = useState([])
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [liveScans, setLiveScans] = useState([]);
    const [filter, setFilter] = useState<filterType>({userSelected: null})
    const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

    const {data: links, refetch: refetchLinks,} = useGetLinksQuery(filter.userSelected?.value);
    const {data: users, refetch: refetchUsers,} = useGetUsersQuery();
    // const links: any[] = [];
    // const refetchLinks = () => {}

    console.log('1111')

    const addButton = (<Button
        id={"add"}
        click={() => {setShowUploadFileModal(true)}}
        size={isMobile ? "large" : "middle"}
        background={"bg-green"}
        text={translate('add')}
        type={"button"}
        classes={`text-capitalize ${localStyles.addButton}`}
        appendBefore={
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 fill="none"
                 id="plus">
                <path fill="#fff"
                      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM11 8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8Z"
                />
            </svg>
        }
    />);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onUpdateLinkEvent(value: any) {
            setList(list.map(item => item.id === value?.id ? value : item))
        }

        function onAddNewLinkEvent(value: any) {
            console.log(list, 'list onAddNewLinkEvent')
            setList([value, ...list])
        }

        const userid = localStorage.getItem('userid')

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        if (userAdminIds.includes(+userid)) {
            socket.on('update_link', onUpdateLinkEvent);
            socket.on('add_new_link', onAddNewLinkEvent);
        } else {
            socket.on('update_link:' + userid, onUpdateLinkEvent);
            socket.on('add_new_link:' + userid, onAddNewLinkEvent);
        }

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            if (userAdminIds.includes(+userid)) {
                socket.off('update_link', onUpdateLinkEvent);
                socket.off('add_new_link', onAddNewLinkEvent);
            } else {
                socket.off('update_link:' + userid, onUpdateLinkEvent);
                socket.off('add_new_link:' + userid, onAddNewLinkEvent);
            }
        };
    }, [list]);

    useEffect(() => {
        setList(links ?? [])
    }, [links]);

    useEffect(() => {
        refetchLinks()
    }, [filter]);

    const handleSelectUser = (value: any) => {
        setFilter({...filter, userSelected: value});
    }

    useEffect(() => {
        console.log(list, 'LIST')
    }, [list])

    // console.log(filter)

    return (<div className={`${localStyles.container}`}>
        <div className={`${localStyles.filters}`}>
            {
                userAdminIds.includes(+userid) ?
                    <SelectFieldComponent placeholder={'User'}
                                          options={(users ?? []).map(user => ({label: user.username, value: user.id.toString()}))}
                                          selectedValue={filter.userSelected}
                                          handleSelect={handleSelectUser}
                    /> : <span></span>
            }
            {!isMobile && addButton}
        </div>
        {list.map((item, i) => (
            <div key={item.id}>
                <FilesListItem {...item}/>
            </div>
        ))}
        <div>
            {isMobile && addButton}
        </div>
        <Modal show={showUploadFileModal}
               width={560}
               element={<FileUploadForm close={() => setShowUploadFileModal(false)}/>}
               handleClose={setShowUploadFileModal} />
    </div>)
}

export default <Links/>;

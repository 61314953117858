import React, {BaseSyntheticEvent, useEffect, useState} from 'react';

import localStyles from './styles.module.scss';
import SelectFieldComponent from "../../components/SelectFieldComponent";
import InputText from "../../components/Inputs/InputText";
import {CoreService} from "../../services/core.service";
import {PreOrderInterface} from "../../interfaces/entity";
import {NavLink} from "react-router-dom";
import {ROUTES} from "../../utils/constants";
import {copy} from "../../utils/methods";
import translate from "../../utils/translate";
import icons from "../../utils/icons";
import Button from "../../components/Button";
import {useLazyGetPreOrdersQuery} from "../../storage/api";

const PreOrders = () => {

    // const [preOrders, setPreOrders] = useState<PreOrderInterface[]>([]);
    const [searchOrder, setSearchOrder] = useState<string>('');
    const [debounceTerm, setDebounceTerm] = useState(searchOrder);

    const [trigger, {data: preOrders, error, isLoading}] = useLazyGetPreOrdersQuery()

    useEffect(() => {
        trigger(searchOrder)
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceTerm(searchOrder);
        }, 200); // Затримка 500 мс

        return () => {
            clearTimeout(handler);
        };
    }, [searchOrder]);

    useEffect(() => {
        if (debounceTerm !== undefined) {
            trigger(debounceTerm);
        }
    }, [debounceTerm, trigger]);

    return (
        <div className={localStyles.container}>
            <InputText id={'search-pre-order'}
                       labelText={'Search Pre-order'}
                       handleChange={(value: string) => setSearchOrder(value)}
            />
            <ol className={localStyles.list}>
                {(preOrders ?? []).map((preOrder, index) => (
                    <li key={index}>
                        <NavLink to={'/' + ROUTES.PRE_ORDERS + '/' + preOrder.id}>
                            <strong>#{preOrder.id}</strong>
                            <Button
                                click={(event: BaseSyntheticEvent) => {
                                    event.preventDefault()
                                    const linkPreOrder = process.env.REACT_APP_API_URI + '/pre-orders/files/' + preOrder.hash
                                    copy(linkPreOrder, null, event)
                                }}
                                id={'copy-link'}
                                size={'middle'}
                                background={'bg-primary'}
                                type={'button'}
                                text={translate('copy_link')}
                                classes={localStyles.copyButton}
                                appendBefore={<img className={localStyles.icon}
                                                   src={icons.copy_all}
                                                   alt="copyImg"
                                />}
                            />
                            <strong>{preOrder.name}</strong>
                            <strong>{(new Date(preOrder.created_at)).toLocaleString().replace(',', '')}</strong>
                        </NavLink>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default <PreOrders/>;
